<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        <chemical-latex content="^{241}Am" />
        is used in smoke detectors because its emitted alpha particle ionizes particles found in
        smoke. The ions created carry current inside the smoke detectors and set off the alarm. The
        half-life of
        <chemical-latex content="^{241}Am" />
        is
        <stemble-latex content="$432.2\,\text{years.}$" />
        If the smoke detector has an initial rate of
        <stemble-latex content="$3.30\times 10^{4}\,\text{Bq}$" />
        what is the rate after
        <number-value :value="time" unit="\text{years?}" />
      </p>

      <calculation-input
        v-model="inputs.rate"
        prepend-text="$\text{Rate:}$"
        append-text="$\text{Bq}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1220A4Q7',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        rate: null,
      },
    };
  },
  computed: {
    time() {
      return this.taskState.getValueBySymbol('time').content;
    },
  },
};
</script>
